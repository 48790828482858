@use 'sass:meta';
// themes - our custom or/and out of the box themes
@use 'themes' as *;

// framework component themes (styles tied to theme variables)
@use '@nebular/theme/styles/globals' as *;
@use '@nebular/auth/styles/globals' as *;

// loading progress bar theme
@use 'pace.theme' as *;

@use 'layout' as *;
@use 'overrides' as *;

@import url('https://fonts.googleapis.com/css?family=Almarai|Merriweather&display=swap');

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';
@import "~@ng-select/ng-select/themes/default.theme.css";

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Merriweather", "Almarai", sans-serif;
}


//tr.ng2-smart-row.selected {
//  cursor: pointer;
//}
tbody tr:nth-child(n){cursor: pointer;}

.ng2-smart-action-add-add {
  visibility: hidden;
}

nb-card {
  opacity: 0.98;
}

nb-layout-column {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: linear-gradient(
      90deg, rgba(36, 172, 224, 0.20521712102809875) 35%, rgba(108, 189, 110, 0.208018241476278) 100%);
  background-color: #d0e4f5;
  background-attachment: local;
  background-size: contain;
}
